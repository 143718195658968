import React, { memo } from 'react';
import { css } from '@emotion/react';
import { Navbar, Center, Stack, UnstyledButton, Tooltip } from '@mantine/core';
import { MdSupervisorAccount, MdLogout } from 'react-icons/md';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { AppLink2 } from '~/components/AppLink2';
import { useFirebaseMeState } from '~/modules/auth/containers/useFirebaseMeState';
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton';
import { GiTeacher } from 'react-icons/gi';
import { BiMessageEdit } from 'react-icons/bi';
import { fr_me } from '~/pages/heineken_template/_fr/fr_me';
export const SideNav = memo(function SideNav(props) {
    const jwt = fr_me.jwt;
    const firebaseMe = useFirebaseMeState.useContainer();
    return (<>
      <Navbar css={css `
          width: 80px;
          padding: 32px 0 80px;
        `}>
        <Center>
          <img width={32} height={32} src='/futuresai/icon.png'/>
        </Center>
        <Navbar.Section grow mt={32}>
          <Stack justify='center' spacing={0}>
            <div css={css `
                margin: auto;
                margin-bottom: 24px;
              `}>
              <UserAvatarAsDialogButton />
            </div>
            <AppLink2 css={linkCss} href='/admin/dashboard'>
              <Tooltip label={`使用者管理`} position='right'>
                <span>
                  <MdSupervisorAccount></MdSupervisorAccount>
                </span>
              </Tooltip>
            </AppLink2>
            <AppLink2 css={linkCss} href='/admin/message'>
              <Tooltip label={`行政發訊`} position='right'>
                <span>
                  <BiMessageEdit></BiMessageEdit>
                </span>
              </Tooltip>
            </AppLink2>
            <AppLink2 css={linkCss} href='/admin/notifier'>
              <Tooltip label={`老師發訊`} position='right'>
                <span>
                  <GiTeacher></GiTeacher>
                </span>
              </Tooltip>
            </AppLink2>
          </Stack>
        </Navbar.Section>
        {jwt ? (<Navbar.Section>
            <Stack mb={40} justify='center' spacing={0}>
              <Tooltip label={`登出`} position='right'>
                <UnstyledButton onClick={() => {
                // fr_me.logoutWithFirebase()
                firebaseMe.acts.logout();
            }} css={flex.h.allCenter}>
                  <MdLogout css={css `
                      transform: scaleX(-1);
                    `}/>
                </UnstyledButton>
              </Tooltip>
            </Stack>
          </Navbar.Section>) : null}
      </Navbar>
    </>);
});
const linkCss = css `
  ${flex.h.allCenter};
  margin-bottom: 8px;
  svg {
    width: 24px;
    height: 24px;
  }
`;

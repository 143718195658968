import React, { memo, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { Button, Select, TextInput } from '@mantine/core';
import { adminStore, productStatusArr, } from '~/pages/admin/components/adminStore';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { css } from '@emotion/react';
import { DatePickerInput } from '@mantine/dates';
import 'dayjs/locale/zh-tw';
import dayAPI from '~/utils/dayAPI';
import { isDate } from 'lodash';
import { apirc } from '~/configs/apirc';
import { useProxy } from 'valtio/utils';
const searchByColumnOptions = [
    { label: '無', key: undefined },
    { label: '目前有效用戶', key: 'validUser' },
    { label: 'UID / 會員代碼', key: 'uid' },
    { label: '姓名', key: 'name' },
    { label: '電子信箱', key: 'email' },
    { label: '開始日期', key: 'startedAt' },
    { label: '到期日期', key: 'expiredAt' },
    { label: '註冊日期', key: 'createdAt' },
    { label: '訂閱類型', key: 'status' },
];
const Filtering = memo(function Filtering({ isNotifierRole, }) {
    const { admin, productGroup, selectedAgent, additionSearchKey, kwName, kwUid, kwEmail, productStatus, selectedProduct, } = useSnapshot(adminStore, { sync: true });
    useProxy(adminStore);
    // const products = useMemo(() => {
    //   if (selectedAgent) {
    //     const agentProducts = admin?.roles.find(role => role.agentName === selectedAgent)
    //     const pseudoProducts = productGroup.map(pg => ({
    //       name: pg.productGroup,
    //       displayName: pg.displayName,
    //       id: -1,
    //       roleType: 'admin' as 'admin' | 'readOnly' | 'notifier',
    //     }))
    //     return sortBy(agentProducts?.products.slice().concat(pseudoProducts), ['name'])
    //   }
    //   return []
    // }, [admin?.roles, selectedAgent, productGroup])
    // const productsForSelect = products?.map(p => ({
    //   value: p.name,
    //   label: p.displayName ? p.name + ' ' + p.displayName : p.name,
    // }))
    // const currentRoleType =
    //   admin && admin.roles && admin.roles.length
    //     ? admin?.roles.length
    //       ? admin?.roles.flatMap(role => role.products).find(p => p.name === selectedProduct)
    //           ?.roleType
    //       : ''
    //     : ''
    // 給初始值
    useEffect(() => {
        if (admin?.roles && admin.roles.length && selectedAgent === '') {
            const firstDefaultAgent = admin?.roles[0];
            adminStore.selectedAgent = firstDefaultAgent.agentName;
            const agentProducts = admin?.roles.find(r => r.agentName === firstDefaultAgent.agentName)
                ?.products;
            if (agentProducts) {
                adminStore.selectedProduct = agentProducts[0].name;
            }
        }
    }, [admin?.roles, productGroup, selectedAgent]);
    // 如果是 商品狀態 或是 回到「無」 就直接 call
    useEffect(() => {
        if (additionSearchKey === 'status' || !additionSearchKey) {
            apirc.admin.getUsers.fetch();
        }
        if (additionSearchKey === 'validUser') {
            adminStore.expiredAfter = dayAPI().toISOString();
            apirc.admin.getUsers.fetch();
        }
    }, [additionSearchKey]);
    useEffect(() => {
        if (selectedAgent) {
            apirc.admin.getProductGroup.fetch();
        }
    }, [selectedAgent]);
    return (<div css={css `
        padding: 12px;
      `}>
      <div css={flex.h.default}>
        {admin && admin.roles ? (<Select label={'業務單位'} css={selectCss} value={selectedAgent} searchable maxDropdownHeight={600} hoverOnSearchChange onChange={value => {
                if (value) {
                    adminStore.selectedAgent = value;
                    const agentProducts = admin?.roles.find(r => r.agentName === value)?.products;
                    if (agentProducts) {
                        adminStore.selectedProduct = agentProducts[0].name;
                    }
                }
            }} data={adminStore.permissions.AllRolesSelectItems}/>) : null}
        {adminStore.productsBySelectedAgent.length ? (<Select label={'產品群組'} searchable css={longerSelectCss} maxDropdownHeight={480} hoverOnSearchChange value={adminStore.selectedProduct} onChange={value => {
                if (value) {
                    const selected = adminStore.productsBySelectedAgent.find(product => product.name === value);
                    if (selected) {
                        adminStore.selectedProductGroup = selected.id === -1 ? selected?.name : '';
                        adminStore.selectedProduct = selected?.name;
                    }
                }
            }} data={adminStore.productsBySelectedAgentOfSelectItems}/>) : null}
        <Select label={'權限'} disabled css={selectCss} value={adminStore.permissions.currentRoleType} data={[
            {
                value: 'admin',
                label: 'admin',
                disabled: adminStore.permissions.currentRoleType !== 'admin',
            },
            {
                value: 'readOnly',
                label: 'readOnly',
                disabled: adminStore.permissions.currentRoleType !== 'readOnly',
            },
            {
                value: 'notifier',
                label: 'notifier',
                disabled: adminStore.permissions.currentRoleType !== 'notifier',
            },
        ]}/>

        {isNotifierRole ? null : (<Select label={'搜尋條件'} css={longerSelectCss} maxDropdownHeight={480} value={searchByColumnOptions.find(option => option.key === additionSearchKey)?.label} onChange={value => {
                if (value) {
                    const selected = searchByColumnOptions.find(option => option.label === value);
                    if (selected) {
                        adminStore.additionSearchKey = selected.key;
                    }
                }
            }} data={searchByColumnOptions.map(option => option.label)}/>)}

        {isNotifierRole ? null : (<div css={css `
              margin-left: 24px;
              ${flex.h.allCenter}
            `}>
            {additionSearchKey === 'expiredAt' ? (<>
                <DatePickerInput {...datepickerShareProps} 
            // defaultValue={dayAPI().startOf('day').add(-7, 'day').toDate()}
            label='到期開始至' onChange={value => {
                    if (isDate(value)) {
                        adminStore.expiredAfter = dayAPI(value).startOf('day').toISOString();
                    }
                }}/>
                <DatePickerInput {...datepickerShareProps} 
            // defaultValue={dayAPI().endOf('day').toDate()}
            label='結束' onChange={value => {
                    if (isDate(value)) {
                        adminStore.expiredBefore = dayAPI(value).endOf('day').toISOString();
                    }
                }}/>
              </>) : null}
            {additionSearchKey === 'startedAt' ? (<>
                <DatePickerInput {...datepickerShareProps} label='訂閱開始至' onChange={value => {
                    if (isDate(value)) {
                        adminStore.subscribeAfter = dayAPI(value).startOf('day').toISOString();
                    }
                }}/>
                <DatePickerInput {...datepickerShareProps} label='結束' onChange={value => {
                    if (isDate(value)) {
                        adminStore.subscribeBefore = dayAPI(value).endOf('day').toISOString();
                    }
                }}/>
              </>) : null}
            {additionSearchKey === 'createdAt' ? (<>
                <DatePickerInput {...datepickerShareProps} label='註冊開始至' onChange={value => {
                    if (isDate(value)) {
                        adminStore.createdAfter = dayAPI(value).startOf('day').toISOString();
                    }
                }}/>
                <DatePickerInput {...datepickerShareProps} label='結束' onChange={value => {
                    if (isDate(value)) {
                        adminStore.createdBefore = dayAPI(value).endOf('day').toISOString();
                    }
                }}/>
              </>) : null}
            {additionSearchKey === 'name' ? (<TextInput value={kwName} onKeyDown={e => {
                    if (e.key === 'Enter' && kwName.trim()) {
                        apirc.admin.getUsers.fetch();
                    }
                }} onChange={e => {
                    adminStore.kwName = e.currentTarget.value;
                }} type='text' label='輸入使用者姓名'/>) : null}
            {additionSearchKey === 'uid' ? (<TextInput value={kwUid} onKeyDown={e => {
                    if (e.key === 'Enter' && kwUid.trim()) {
                        apirc.admin.getUsers.fetch();
                    }
                }} onChange={e => {
                    adminStore.kwUid = e.currentTarget.value;
                }} type='text' label='輸入完整或部份的UID或會員代碼'/>) : null}
            {additionSearchKey === 'status' ? (<Select label={'商品狀態'} value={productStatus} css={longerSelectCss} onChange={(value) => {
                    adminStore.productStatus = value;
                    apirc.admin.getUsers.fetch();
                }} data={productStatusArr.slice()}/>) : null}
            {additionSearchKey === 'email' ? (<TextInput value={kwEmail} onKeyDown={e => {
                    if (e.key === 'Enter' && kwEmail.trim()) {
                        apirc.admin.getUsers.fetch();
                    }
                }} onChange={e => {
                    adminStore.kwEmail = e.currentTarget.value;
                }} type='text' label='輸入完整或部份的信箱'/>) : null}
            {additionSearchKey === 'createdAt' ||
                additionSearchKey === 'startedAt' ||
                additionSearchKey === 'expiredAt' ? (<Button ml={24} size='lg' onClick={() => {
                    apirc.admin.getUsers.fetch();
                }}>
                搜尋
              </Button>) : null}
          </div>)}
      </div>
    </div>);
});
const selectCss = css `
  width: 120px;
  min-width: 120px;
`;
const longerSelectCss = css `
  width: 200px;
  min-width: 200px;
`;
const datepickerShareProps = {
    clearable: true,
    valueFormat: 'YYYY/MM/DD',
    maw: 200,
    locale: 'zh-tw',
    firstDayOfWeek: 0,
};
export default Filtering;

import { memo, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { css } from '@emotion/react';
import { adminNotifyPushStore } from '~/pages/admin/components/adminStore';
import { Alert } from '@mantine/core';
const NotificationSign = memo(function NotificationSign() {
    const { notificationTitle, notificationShow } = useSnapshot(adminNotifyPushStore);
    useEffect(() => {
        const intervalId = setInterval(() => {
            adminNotifyPushStore.notificationShow = false;
        }, 5000);
        return () => clearInterval(intervalId);
    });
    if (!notificationShow)
        return null;
    return (<Alert css={css `
        position: fixed;
        top: 24px;
        right: 12px;
        z-index: 2;
        width: 30%;
      `} title={notificationTitle} color='violet' variant='filled' withCloseButton onClose={() => {
            adminNotifyPushStore.notificationShow = false;
        }}>
      {''}
    </Alert>);
});
export default NotificationSign;
